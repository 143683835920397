/* eslint-disable */
import React from 'react';
// import Text from '../../components/Text';
import Container from '../../components/Container';
import BannerWrapper, { BannerContent } from './impressum.style';

const Impressum = () => {
  return (
    <BannerWrapper id='impressum'>
      <Container>
        <BannerContent>
          <h1>Impressum</h1>
          <h2 id='m46'>Diensteanbieter</h2>
          <p> Aayush Singla</p>
          <p> Enhuberstr 2</p>
          <p> 80333, München</p>
          <p> Deutschland</p>
          <h2 id='m56'>Kontaktmöglichkeiten</h2>
          <p>
            <strong>E-Mail-Adresse</strong>: <a href='mailto:aayush.singla@yush.app'>aayush.singla@yush.app</a>
          </p>
          <p>
            <strong>Telefon</strong>: +491756664423
          </p>
          <h2 id='m172'>Social Media und andere Onlinepräsenzen</h2>
          <p>
            <strong>Dieses Impressum gilt auch für die folgenden Social-Media-Präsenzen und Onlineprofile</strong>:
          </p>
          <p>
            <a href='https://www.instagram.com/yush.app/' target='_blank'>
              https://www.instagram.com/yush.app/
            </a>
          </p>
          <h2 id='m65'>Haftungs- und Urheberrechtshinweise</h2>
          <p>
            <strong>Urheberrechte und Markenrechte</strong>: Alle auf dieser Website dargestellten Inhalte, wie Texte,
            Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte,
            Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten
            der jeweiligen Urheber bzw. Rechteverwalter.
          </p>
          <p>
            <strong>Hinweise auf Rechtsverstöße</strong>: Sollten Sie innerhalb unseres Internetauftritts Rechtsverstöße
            bemerken, bitten wir Sie uns auf diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach
            Kenntnisnahme unverzüglich entfernen.
          </p>
          <p>
            <a
              href='https://datenschutz-generator.de/?l=de'
              title='Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken.'
              target='_blank'
              rel='noopener noreferrer nofollow'
            >
              Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke
            </a>
          </p>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default Impressum;
